
import {Text, Box, Divider} from '@mantine/core'

const TicketFooter = () =>
{
  return (
    <div>
      <Box className='hidden print:block'>
        <Box className='flex flex-col gap-2 items-center justify-center my-2'>
          <Text fz="xs" className='hidden print:block text-center ticketFont capitalize'>Ubicación</Text>
          <Text fz="xs" className='hidden print:block text-center ticketFont'>Unión 162, Industrial, GAM. , CDMX, CP. 07020</Text>
        </Box>

        <Text fz="xs" className='hidden print:block text-center ticketFont'>Teléfonos</Text>
        <Box className='flex flex-row gap-2 items-center justify-center my-1'>
          <Text fz="xs" className='hidden print:block text-center ticketFont'>55 8926 3500</Text>
          <Text fz="xs" className='hidden print:block text-center ticketFont'>55 4588 1106</Text>
        </Box>
        <Box className='pb-8 mb-4 flex flex-col'>
          <Text fz="xs" className='hidden print:block text-center ticketFont'>Horarios</Text>
          <Box className='flex flex-col gap-2 items-center justify-center'>
            <Text fz="xs" className='hidden print:block text-center ticketFont'>LUNES A SÁBADO DE 12:00 A 22:00 HRS</Text>
            <Text fz="xs" className='hidden print:block text-center ticketFont'>DOMINGO DE 12:00 A 17:00 HRS</Text>
            <br />
          </Box>
        </Box>
      </Box>
      <br />
      <br />
      <br />
      <br />
      <Divider my={ 2 } className='text-gray-300'/>
    </div>
  )
}

export default TicketFooter