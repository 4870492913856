import { Card, Title, Table } from '@mantine/core'
import { RowOrderType } from './RowOrderType'

export const OrderType = ({ dailyOrderType }) =>
{
  return (
    <Card className="w-12/12" p="md" withBorder radius="md">
      <Title order={ 4 } className='uppercase'>Tipo de Venta</Title>
      { dailyOrderType ?
        <>
          <Table highlightOnHover>
            <Table.Thead>
              <Table.Tr>
                <Table.Td></Table.Td>
                <Table.Td className='text-center'>Cnt</Table.Td>
                <Table.Td className='text-right'>Total</Table.Td>
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              <RowOrderType name="Local" { ...dailyOrderType?.local } />
              <RowOrderType name="Delivery" { ...dailyOrderType?.delivery } />

              <RowOrderType name="Pickup" { ...dailyOrderType?.pickup } />
            </Table.Tbody>
          </Table>



        </> : null
      }
    </Card>
  )
}
