import React, { useContext, useRef, useState, useEffect } from 'react'
import { useReactToPrint } from 'react-to-print';
import { useNavigate } from 'react-router'
import dayjs from 'dayjs'

import { Box, Card, Button, Flex, ActionIcon, Tooltip, Text, Divider, Avatar, Loader } from '@mantine/core';
import { PiTrashDuotone, PiPrinterDuotone, PiChairDuotone, PiBagDuotone, PiMotorcycleDuotone } from 'react-icons/pi'
import relativeTime from 'dayjs/plugin/relativeTime'
import locale_es from 'dayjs/locale/es-mx'
import TicketCloseOrder from '../Tickets/TicketCloseOrder'
import AuthContext from '../../contexts/Auth/authContext'
import AlertContext from '../../contexts/Alert/alertContext'
import { getTotal, getIva } from '../../utils/functions/getTotal'
import { get_order } from '../../utils/apis/orders'
import { localOrder } from '../../utils/functions/generateLocalCart';


dayjs.extend(relativeTime)

const ComponentToPrint = React.forwardRef((props, ref) =>
{

  return (
    <div className="hidden print:block" ref={ ref }>
      <TicketCloseOrder id={ props.id } order={ props.order } orderDetail={ props.orderDetail } orderInfo={ props.orderInfo } subTotal={ props.subTotal } total={ props.total } ivaAmount={ props.ivaAmount } />

    </div>
  );
});
const OrderView = ({ order, cancel }) =>
{
  const authContext = useContext(AuthContext);
  const { user } = authContext;
  const alertContext = useContext(AlertContext);
  const [ isLoadingTicket, setIsLoadingTicket ] = useState(false);
  const [ isLoading, setIsLoading ] = useState(false);
  const { showAlert } = alertContext;
  const [ subTotal, setSubtotal ] = useState();
  const [ ivaAmount, setIvaAmount ] = useState();
  const [ total, setTotal ] = useState();
  const [ orderTicket, setOrderTicket ] = useState(null)
  const [ orderDetail, setOrderDetail ] = useState(null)
  const [ orderInfo, setOrderInfo ] = useState({
    table: '',
    persons: '',
    name: '',
    phone: '',
    pickUpDate: '',
    pickUpTime: '',
    address: ''
  });
  const navigate = useNavigate()
  const editOrder = async (id) =>
  {
    navigate(`/orders/${ id }`)
  }

  const validateProductStatus = (order) =>
  {
    let left = order.orderProducts.filter(item => item.statusProductOrder === false).length
    if (left !== 0) {
      showAlert(null, `Hay ${ left } ${ left === 1 ? 'producto pendiente' : 'productos pendientes' } por entregar, no se puede cerrar la cuenta`, 'warning')
      return true

    }
  }

  const goToPos = (id) =>
  {
    if (validateProductStatus(order)) { return }

    navigate(`/pos/${ id }`)
  }




  const calcSummary = (items) =>
  {
    const orderTotal = getTotal(items);
    const ivaAmount = getIva(orderTotal)
    setSubtotal(orderTotal.toFixed(2));
    setIvaAmount(ivaAmount);
    setTotal(orderTotal.toFixed(2))
  }
  const getOrder = async (id) =>
  {
    setIsLoadingTicket(true);
    setIsLoading(true);
    try {
      const res = await get_order(id);
      setOrderDetail(res.results);
      setOrderInfo(res.results.orderInfo);
      const items = await localOrder(res.results.orderProducts);
      setOrderTicket(items);
      calcSummary(items);
    } catch (error) {
      console.error('Error al obtener la orden', error);
    } finally {
      setIsLoading(false);
    }
  };

  const printOrder = async (id) =>
  {
    if (validateProductStatus(order)) { return }
    await getOrder(id);
  };

  useEffect(() =>
  {
    if (!isLoading && orderTicket) {
      handlePrint();
    }
  }, [ orderTicket, isLoading ]);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () =>
    {
      const current = componentRef.current;
      if (current) {
        current.style.marginBottom = '20mm'; // Ajuste dinámico
      }
      return current;
    },
    // onBeforeGetContent: async () =>
    // {
    //   await getOrder(order._id);
    // },
    onAfterPrint: () =>
    {
      setIsLoadingTicket(false);
    }
  });
  if (order.status === 'open') {
    return (
      <Box mb={ 2 } className='flex flex-col items-center'>
        <ComponentToPrint ref={ componentRef } id={ order._id } order={ orderTicket } orderDetail={ orderDetail } orderInfo={ orderInfo } subTotal={ subTotal } total={ total } ivaAmount={ ivaAmount } />
        <Card radius="xl" withBorder className='w-full' px="md" mb="xs" shadow="sm">
          <div className='flex flex-row items-center justify-between'>
            <Flex gap="sm" className='flex flex-row items-center justify-between'>
              { order?.orderType === 'local' && <PiChairDuotone className='text-xl' /> }
              { order?.orderType === 'delivery' && <PiMotorcycleDuotone className='text-xl' /> }
              { order?.orderType === 'pickup' && <PiBagDuotone className='text-xl' /> }
              <Text fz="xs" className='mx-2'>{ dayjs().locale(locale_es).to(dayjs(order.date)) }</Text>
            </Flex>
            <Box className='flex flex-row gap-2 justify-center items-center'>
              { user.role.includes('CASHIER') && <Tooltip label="Imprimir ticket" aria-label='A tooltip' >{ !isLoadingTicket ?
                <ActionIcon size="xl" radius="lg" variant="light" color="gray" onClick={ () => printOrder(order._id) } >
                  <PiPrinterDuotone className='text-2xl' />
                </ActionIcon>
                :
                <Box mx='2' my='2'>
                  <Loader color="blue" size="xs" type="dots" />
                </Box>

              }</Tooltip> }
            </Box>

          </div>
          <Box className='flex flex-row gap-2 items-center'>
            <Tooltip label={ order?.user?.name } aria-label='Nombre de quien tomo la orden'>
              <Avatar name={ order?.user?.name } size='sm' color="grape" />
            </Tooltip>
            <Text fz="sm" >{ order?.user?.name }</Text>
          </Box>
          <Divider className='my-2' />
          <div className='grid grid-cols-1 justify-between items-center'>
            <Text fz="sm">{ order.orderInfo?.table } - { order.orderInfo?.name }</Text>
            <Box>
              <Divider className='my-2' variant="dashed" />
              { order?.total ? <Flex  justify="flex-end" gap="sm" >
                <Text fw="normal">Total:</Text>
                <Text fw="bolder">$ { order?.total?.toFixed(2) }</Text>
              </Flex>   :
                <Flex  justify="flex-end" gap="sm" >
                  <Text fw="normal">Total:</Text>
                  <Text fw="bolder">$ 0.00</Text>
                </Flex>
              }

            </Box>


          </div>
        </Card>
        <Card radius="xl" withBorder className='w-full' mb="xs" p="xs">
          <Flex align="center" justify="center" gap="sm">
            { user.role.includes('MANAGER') &&
              <Tooltip label="Cancelar" aria-label='A tooltip' >
                <ActionIcon size="xl" radius='lg' variant="light" color="red" onClick={ () => { cancel(order._id, order.orderProducts) } } >
                  <PiTrashDuotone className='text-2xl' />
                </ActionIcon>
              </Tooltip>

            }
            <Tooltip label="Editar orden" aria-label='A tooltip' >
              <Button
                autoContrast
                variant="light"
                color="gray"
                size="md"
                radius="lg"
                onClick={ () => editOrder(order._id) } >Editar</Button>
            </Tooltip>
            { user.role.includes('CASHIER') &&
              <Tooltip label="Cobrar" aria-label='A tooltip' >
                <Button autoContrast variant="light" color="blue" size="md" radius="lg" onClick={ () => goToPos(order._id) } >
                  Pagar
                </Button>
              </Tooltip> }
          </Flex>

        </Card>
      </Box>

    );
  }

}

export default OrderView