import { Button, Text, SimpleGrid, Card } from '@mantine/core'

const MenuView = ({ categories, changeCategory, selected }) =>
{
  return (
    <SimpleGrid cols={ { base: 2, sm: 2, md: 3 } } className='w-full'>
      { categories && categories.map((category) => (

        <Button fullWidth size="xl" className='text-left my-1 mx-1' key={ category._id } onClick={ () => { changeCategory(category._id) } } variant="filled" radius="md" color={ selected === category._id ? 'indigo' : 'gray' } >
          <Text lineClamp={ 4 }>   { category.name }</Text>
        </Button>

      ))
      }

    </SimpleGrid>
  )
}

export default MenuView