export const countTransactions = (transactions) =>
{
  if(transactions.length === 0) return null
  return transactions.reduce((acc, transaction) =>
  {
    const orderType = transaction?.orderType.toLowerCase();

    if (orderType === 'local' || orderType === 'delivery' || orderType === 'pickup') {
      acc[ orderType ].totalTransactions += 1;
      acc[ orderType ].totalAmount += transaction.total;
    }

    return acc;
  }, {
    local: { totalTransactions: 0, totalAmount: 0 },
    delivery: { totalTransactions: 0, totalAmount: 0 },
    pickup: { totalTransactions: 0, totalAmount: 0 }
  });
};