import { Card, Title, Table , Box} from '@mantine/core'
import OrderResumenRow from './OrderResumenRow'
import { getTotal } from '../../utils/functions/getTotal'
import Loading from '../Loading'


const OrderResume = ({ order, summary, setSummary }) =>
{


  return (
    <>
      { order.length > 0 ?
        <Card withBorder shadow='md' my="md" radius="xl" fullWidth>
          <Title order={ 4 } className='text-xl font-bold mb-2'>Productos</Title>
          <Table className='w-full' fullWidth>
            <Table.Tbody>
              {
                order ? order.map(item => (
                  <OrderResumenRow key={ item._id } product={ item } />
                ))
                  : null
              }
            </Table.Tbody>
          </Table>
        </Card>
        :
        <Box className='w-full items-center flex flex-row justify-center' p={ 2 } mt={ 2 } borderWidth='1px' borderRadius='lg'>
          <Loading />
        </Box>
      }



    </>
  )
}

export default OrderResume