/* eslint-disable import/no-anonymous-default-export */
import { useReducer } from 'react';
import settingReducer from './settingReducer';
import settingContext from './settingContext'
import
    {
        LIST_SETTINGS,
        ADD_SETTING,
        UPDATE_SETTING
    } from '../types';
import { get_setting, update_setting } from '../../utils/apis/settings';

const SattingState = props =>
{
    const initialState = {
        settings: localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings')) : [],
        loading: true
}

const [ state, dispatch ] = useReducer(settingReducer, initialState);

const getSettings = async () =>
{
    try {
        const res = await get_setting();
        console.log(res)
        dispatch({
            type: LIST_SETTINGS,
            payload: res.results
        })
    } catch (error) {

    }
}

const updateSettings = async (_id,data) =>
{
    try {
        const res = await update_setting(_id,data);
        console.log(res)
        dispatch({
            type: UPDATE_SETTING,
        })
    } catch (error) {

    }
}


return (
    <settingContext.Provider
        value={ {
            settings: state.settings,
            loading: state.loading,
            getSettings,
            updateSettings

        } }
    >
        {
            props.children
        }
    </settingContext.Provider>
)

}


export default SattingState
