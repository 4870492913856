import { Button, Badge, Flex, Text } from '@mantine/core'
import { PiChairDuotone, PiBagDuotone, PiStackDuotone, PiMotorcycleDuotone } from 'react-icons/pi';
const OrderIconReference = ({ orders = null, style = 'subtle', orderTypeVisible = 'all', setOrderTypeVisible = null }) =>
{
  const changeView = (type) => { setOrderTypeVisible && setOrderTypeVisible(type) }
  return (
    <Flex direction={{base:"row"}} wrap={{base:"wrap", md:"nowrap"}} justify={{base:'space-between', md:'flex-start'}} align={{base:'stretch'}} gap="md" my="md">
      <Button size="md" autoContrast radius="lg" color={ orderTypeVisible === 'all' ? 'indigo' : 'gray.9' } variant={ orderTypeVisible === 'all' ? 'filled' : style } onClick={ () => changeView('all') }
        leftSection={ <PiStackDuotone className="text-2xl" /> }
        rightSection={ <Badge radius="lg" color="gray.9" size="lg" variant={ orderTypeVisible !== 'all' ? 'filled' : "white" }>{ orders  !== null ? orders.length :'0' }</Badge > }>
        <Text className='hidden lg:flex'>Todos</Text>
      </Button>
      <Button size="md" autoContrast radius="lg"  color={ orderTypeVisible === 'local' ? 'indigo' : 'gray.9' } variant={ orderTypeVisible === 'local' ? 'filled' : style }
        onClick={ () => changeView('local') }
        leftSection={ <PiChairDuotone className="text-2xl" /> }
        rightSection={ <Badge radius="lg" color="gray.9" size="lg" variant={ orderTypeVisible !== 'local' ? 'filled' : "white" }>{ orders  !== null ?  orders.filter((order) => order.orderType === 'local').length :'0' }</Badge> }>
        <Text className='hidden lg:flex'>Local</Text> 
      </Button>
      <Button size="md" autoContrast radius="lg"  color={ orderTypeVisible === 'pickup' ? 'indigo' : 'gray.9' } variant={ orderTypeVisible === 'pickup' ? 'filled' : style } onClick={ () => changeView('pickup') }
        leftSection={ <PiBagDuotone className="text-2xl" /> }
        rightSection={ <Badge radius="lg" color="gray.9" size="lg" variant={ orderTypeVisible !== 'pickup' ? 'filled' : "white" }>{ orders  !== null ?  orders.filter((order) => order.orderType === 'pickup').length :'0' }</Badge> }>
       <Text className='hidden lg:flex'>Para llevar</Text>
      </Button>

      <Button size="md" autoContrast radius="lg"  color={ orderTypeVisible === 'delivery' ? 'indigo' : 'gray.9' } variant={ orderTypeVisible === 'delivery' ? 'filled' : style } onClick={ () => changeView('delivery') }
        leftSection={ <PiMotorcycleDuotone className="text-2xl" /> }
        rightSection={ <Badge radius="lg" color="gray.9" size="lg" variant={ orderTypeVisible !== 'delivery' ? 'filled' : "white" }>{orders  !== null ?  orders.filter((order) => order.orderType === 'delivery').length : '0' }</Badge> }>
        <Text className='hidden lg:flex'>A domicilio</Text>
      </Button>
    </Flex>
  )
}

export default OrderIconReference