/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { SocketContext } from '../contexts/Socket/socket';
import AlertContext from '../contexts/Alert/alertContext';
import { Navigate } from 'react-router-dom'
import { AppShell, Burger, Group, Skeleton, ScrollArea, Text } from '@mantine/core';
import packageJson from '../../package.json'
import { useDisclosure } from '@mantine/hooks';
import Sidebar from '../components/Sidebar/Sidebar';
import Header from '../components/Header/Header';
import AuthContext from '../contexts/Auth/authContext';
import ChangePassword from '../components/Modal/ChangePassword';
import { Alerta } from '../components/Alerta/Alerta';
import useVaidateExpiredDate from '../hooks/validateExpiredDate';
import ModalExpired from '../components/Modal/ModalExpired';

const PrivateRoute = ({ children }) =>
{
    const [ opened, { toggle } ] = useDisclosure();

    const { hoy, expired, expiredDate, loadingExpirated } = useVaidateExpiredDate();
    const socket = useContext(SocketContext);
    const alertContext = useContext(AlertContext);
    const { alerta, showAlert } = alertContext;
    const authContext = useContext(AuthContext);
    const [ showText, setShowText ] = useState(true);
    const { token, user } = authContext;
    const [ showSidebar, setShowSidebar ] = useState(false);
    const [ showModal, setShowModal ] = useState(false);
    const [ showChangePassword, setShowChangePassword ] = useState(false);
    const navigate = useNavigate();
    const show = () =>
    {
        setShowSidebar(!showSidebar)
    }

    useEffect(() =>
    {
        socket.on('disconnect', (payload) =>
        {
            showAlert('Desconectado', 'error')
        })
    }, [ socket ])

    useEffect(() =>
    {
        if (!token) {
            // Si no hay token, redirige a la página de inicio de sesión
            navigate('/');
            return; // Evita que se realice la verificación si no hay token
        }

        if (hoy > expiredDate) {
            setShowModal(true);
        }
    }, [ token, hoy, expiredDate, navigate ]);

    return token ?
        <AppShell
            header={ { height: 60 } }
            navbar={ { width: 180, breakpoint: 'sm', collapsed: { mobile: !opened } } }
            padding="md"
        >
            <AppShell.Header>
                {/* <Group h="100%" px="md">
        <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
        <MantineLogo size={30} />
      </Group> */}
                <Header show={ opened } showSidebar={ toggle } setShowChangePassword={ setShowChangePassword } setShowText={ setShowText } showText={ showText } />
            </AppShell.Header>
            { user?.role.length > 1 ? <AppShell.Navbar p="md">
                <AppShell.Section  grow my="md" component={ ScrollArea }>
                    <Sidebar showSidebar={ showSidebar } show={ show } showText={ showText } />
                </AppShell.Section>
                <AppShell.Section>
                    <Text fz="xs" className='text-center'>
                        V.{ packageJson.version }
                    </Text>
                </AppShell.Section>
            </AppShell.Navbar> : null }
            <AppShell.Main className='relative'>{ children }</AppShell.Main>
            <ChangePassword id={ user._id } show={ showChangePassword } setShow={ setShowChangePassword } />
            <ModalExpired show={ hoy > expiredDate } expired={ expired } />
            { alerta?.msg === 'Desconectado' && <Alerta alerta={ alerta } /> }
        </AppShell>



        : <Navigate to={ '/login' } replace />
}

export default PrivateRoute
