import React, { useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Card, Flex, Button, ActionIcon, Textarea, Select, Title, TextInput, Center } from '@mantine/core';
import {PiArrowLeftDuotone} from 'react-icons/pi'
import { post_category, get_category, update_category } from '../../../utils/apis/categories';

const Category = () =>
{
  const navigate = useNavigate();
  const [ _id, setId ] = useState(null)
  let { id } = useParams()
  useEffect(() =>
  {
    (async () =>
    {
      if (id) {

        setId(id)
        const res = await get_category(id)
        formik.setFieldValue('name', res.results.name)
        formik.setFieldValue('status', res.results.status ? 'visible' : 'hide')
      }
    })()
  }, [])

  const resteFormikValue = async () =>
  {
    formik.resetForm()
  }
  const formik = useFormik({
    initialValues: initialValues(),
    validationSchema: Yup.object(validationSchema()),
    onSubmit: async (data) =>
    {
      const cat = {
        name: data.name,
      }
      if (data.status === 'visible') {
        cat.status = true;
      }

      if (data.status === 'hide') {
        cat.status = false;
      }

      if (_id) {
        await update_category(_id, cat);
      } else {
        await post_category(cat)
      }
      resteFormikValue()
      navigate(-1)
    }
  })
  return (
    <div className='flex flex-col gap-4'>
      <div className='flex flex-row flex-wrap md:flex-row items-start md:items-center justify-between'>
        <div className='flex flex-row items-center justify-start gap-2'>
          <ActionIcon variant="subtle" size="md" c="gray" aria-label='Regresar' onClick={ () => navigate(-1) } >
            <PiArrowLeftDuotone className='text-2xl' />
          </ActionIcon>
          <Title order={ 1 } >{ id ? formik.values.name : 'Crear nueva Categoría' }</Title>
        </div>
        <div className='flex flex-row md:flex-row gap-2 w-full md:w-auto'>
          <Button size="lg" radius="md" color='gray' variant="light" onClick={ () => navigate(-1) } >Descartar</Button>
          <Button size="lg" radius="md" color='blue' onClick={ () => { formik.handleSubmit() } }>Guardar</Button>
        </div>
      </div>

      <Center>
        <Flex direction="column" gap="md" style={ { width: '100%' } } align="center">
          <div className='w-12/12 md:w-8/12'>
            <Card shadow='md' withBorder px="md" py="md" radius="lg" >
              <TextInput
                variant='filled'
                size='lg'
                radius="md"
                label="Nombre"
                placeholder='Nombre de la categoría'
                value={ formik.values.name }
                onChange={ (e) => formik.setFieldValue('name', e.target.value) } />

              <Textarea
                variant='filled'
                size='lg'
                radius="md"
                label="Descripción"
                placeholder=''
                value={ formik.values.description }
                onChange={ (e) => formik.setFieldValue('description', e.target.value) } />


            </Card>
          </div>
          <div className='w-12/12 md:w-8/12 flex flex-col gap-4'>
            <Card shadow='md' withBorder px="md" py="md" radius="lg" >
              <Select
                variant='filled'
                size='lg'
                radius="md"
                value={ formik.values.status }
                label="Visibilidad"
                placeholder="Selecciona una opción"
                onChange={ (e) => formik.setFieldValue('status', e) }
                data={ [ {
                  value: 'visible',
                  label: 'Visible'
                }, {
                  value: 'hide',
                  label: 'Oculto'
                } ] }
              />
            </Card>
          </div>
        </Flex>
      </Center>

    </div>
  )
}

export default Category;

function initialValues ()
{
  return {
    name: '',
    status: '',
    description: '',
    image: ""
  }
}

function validationSchema ()
{
  return {
    name: Yup.string().required(true),
    status: Yup.string(),
    description: Yup.string(),
    image: Yup.string()

  }
}